import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import io from 'socket.io-client';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorIcon from '@mui/icons-material/Error';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactJson from 'react-json-view'
import TimerIcon from '@mui/icons-material/Timer';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from 'react-time-ago'
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import TextField from '@mui/material/TextField';
import GroupsIcon from '@mui/icons-material/Groups';
import CheckIcon from '@mui/icons-material/Check';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactComponent as HazardViewLogo } from './hazardview-logo.svg';

import './Style_Dashboard.scss'
TimeAgo.addDefaultLocale(en);

let mdColors = [
  "#FF33FF", "#FFFF99", "#00B3E6", 
  "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC",
  "#00E680", "#4D8066", "#809980", "#E6FF80", "#1AFF33",
  "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
  "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A", 
  "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC",
  "#00E680", "#4D8066", "#809980", "#E6FF80", "#1AFF33"
];

const projectId = "PRO-TNHP-1";

export default function Dashboard({geocore, geocore_connection}) {

  useEffect(() => {
    getProjectData();
  }, []);

  const [editingApp, setEditingApp] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [copyUserT, setCopyUserT] = useState(false);
  const [copyPlace, setCopyPlace] = useState(false);
  const [copyUserTPass, setCopyUserTPass] = useState(false);

  const [copyCompany, setCopyCompany] = useState(false);
  const [copySubgroup, setCopySubgroup] = useState(false);

  const [searchPlaces, setSearchPlaces] = useState([{place_search_id: null, loading: false, error: false, data: null}]);
  const [searchUsers, setSearchUsers] = useState([{user_search_id: null, loading: false, error: false, data: null, groups: null}]);
  const [searchGroups, setSearchGroups] = useState([{group_search_id: null, loading: false, error: false, data: null}]);

  const [hazardviewApp, setHazardviewApp] = useState({
      android: {version: "n/a", last_updated: 'n/a', url: 'n/a'},
      ios: {version: "n/a", last_updated: 'n/a', url: 'n/a'}
    });

  const [editAppLoading, setEditAppLoading] = useState(false);

  const open = Boolean(anchorEl);

  const [openDialog, setOpenDialog] = React.useState(false);

  const getProjectData = async() => {
    //only have android hazardview to fetch for now
    try {
      const projectData = await geocore.objects.get(projectId);
      setHazardviewApp({
        android: {version: projectData.customData["apk.version"]? projectData.customData["apk.version"]: "n/a", last_updated: projectData.updateTime, url: projectData.customData["apk.url"]? projectData.customData["apk.url"]: "n/a"},
        ios: {version: projectData.customData["hazardview.ios.version"]? projectData.customData["hazardview.ios.version"]: "n/a", last_updated: projectData.updateTime, url: projectData.customData["hazardview.ios.url"]? projectData.customData["hazardview.ios.url"]: "https://v1.geocore.jp/api/public/ios/hazardview"}
      });
      console.log(projectData);
      setEditAppLoading(false);
      setOpenDialog(false)
    } catch (e) {
      console.log("error hit");
      setEditAppLoading(false);
      setOpenDialog(false);
      console.log(e);
    }
  }

  const handleAppUpdatePush = async() => {
    console.log("updating with this info", editingApp);
    try {
      setEditAppLoading(true);
      /*
      apk.url
      : 
      "http://hazardphotos.geocore.jp/release/hazardview-v4.80.00.apk"
      apk.version
      : 
      "4.80.00"
      geocore.version
      : 
      "v2"
      msg.OBJECT.BIN.UPLOAD.WORK.enabled
      : 
      "true"

        geocore.objects.customData.update = function(id, key, value) {
          return geocore.post('/objs/' + id + '/customData/' + key + '/' + value, null);
        };

      */

      if(editingApp.platform === "iOS") {
        const updateVersion = await geocore.objects.customData.update(projectId, "hazardview.ios.version", `${editingApp.version}`);
        //const updateUrl = await geocore.objects.customData.update(projectId, "hazardview.ios.url", `${editingApp.url}`);
      } else if(editingApp.platform === "Android") {
        console.log("don't edit yet")
        //const updateVersion = await geocore.objects.customData.update(projectId, "apk.version", editingApp.version);
        //const updateUrl = await geocore.objects.customData.update(projectId, "apk.url", editingApp.url);
      }

      getProjectData();
    } catch(e) {
      console.log(e);
      setEditAppLoading(false);
    }
  };

  const handleClickOpenDialog = (platform, name) => {
    let version;
    let url;

    switch(name) {
      case "Hazardview":
        if(platform === "iOS") {
          version = hazardviewApp.ios.version;
          url = hazardviewApp.ios.url;
        } else {
          version = hazardviewApp.android.version;
          url = hazardviewApp.android.url;
        }
        break;
    }
    setEditingApp({
      name: name,
      platform: platform,
      version: version,
      url: url,
    });

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditingApp(null);
    setOpenDialog(false);
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddPlaceSearch = () => {
    setSearchPlaces(prevPlaces => [...prevPlaces, { place_search_id: null, loading: false, error: false, data: null}]);
  }

  const handleRemoveSearch = (indexToRemove) => {
    setSearchPlaces(prevPlaces => prevPlaces.filter((_, index) => index !== indexToRemove));
  }

  const handleRemoveSearchUser = (indexToRemove) => {
    setSearchUsers(prevUsers => prevUsers.filter((_, index) => index !== indexToRemove));
  }

  const handleRemoveSearchGroup = (indexToRemove) => {
    setSearchGroups(prevGroup => prevGroup.filter((_, index) => index !== indexToRemove));
  }

  const handleInputChange = (index, value) => {
    setSearchPlaces(prevPlaces => {
      const updatedPlaces = [...prevPlaces];
      updatedPlaces[index].place_search_id = value;
      return updatedPlaces;
    });
  }

  const handleInputChangeUser = (index, value) => {
    setSearchUsers(prevUsers => {
      const updatedUsers = [...prevUsers];
      updatedUsers[index].user_search_id = value;
      return updatedUsers;
    });
  }

  const handleInputChangeGroup = (index, value) => {
    setSearchGroups(prevGroup => {
      const updatedGroup = [...prevGroup];
      updatedGroup[index].group_search_id = value;
      return updatedGroup;
    });
  }

  const handleSearchPlace = async(index) => {
    try {
      setSearchPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[index].loading = true;
        return updatedPlaces;
      });

      const place_id = searchPlaces[index].place_search_id;

      var sendDate = performance.now();
      var place = await geocore.places.get(place_id);
      var receiveDate = performance.now();
      var responseTimeMs = receiveDate - sendDate;
      setSearchPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[index].loading = false;
        updatedPlaces[index].data = place;
        updatedPlaces[index].error = false;
        updatedPlaces[index].responseTimeMs = responseTimeMs.toFixed(2);
        return updatedPlaces;
      });
      getPlaceImageBins(place_id, index);
    } catch (e) {
      setSearchPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[index].error = e;
        return updatedPlaces;
      });
    }
  };

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  const getPlaceImageBins = async(place_id, index) => {
    try {
      setSearchPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[index].loadingPhotosAndVideo = true;
        return updatedPlaces;
      });

      geocore.objects.bins.list = function(id, quality) {
        return geocore.get('/objs/' + id + `/bins?quality=${quality}`);
      };

      const binsList = await geocore.objects.bins.list(place_id, true);

      let photoData = [];
      let videoData = [];

      binsList.reverse();

      for (const binary of binsList) {
        if (binary.includes("photo")) {
          await sleep(1000);
          var sendDate = performance.now();
          const urlPromise = await geocore.objects.bins.url(place_id, binary);
          var receiveDate = performance.now();
          var responseTimeMs = receiveDate - sendDate;
          console.log(urlPromise.key, " timing is ", responseTimeMs.toFixed(2))
          urlPromise.responseTimeMs = responseTimeMs.toFixed(2);


          // Wrap the image loading in a Promise
          var img = new Image();
          const loadImage = new Promise((resolve, reject) => {
            img.onload = function() {
              resolve({ width: this.width, height: this.height });
            };
            img.onerror = reject;
            img.src = urlPromise.url;
          });

          try {
            // Wait for the image to load
            const dimensions = await loadImage;
            console.log("Image dimensions: " + dimensions.width + "x" + dimensions.height + "px");

            // Add these dimensions to your urlPromise object
            urlPromise.imageWidth = dimensions.width;
            urlPromise.imageHeight = dimensions.height;
          } catch (error) {
            console.log('Error loading image: ', error);
          }


          photoData.push(urlPromise);
        }
      };


      for (const binary of binsList) {
        if (binary.includes("video")) {          
          const urlPromise = await geocore.objects.bins.url(place_id, binary)
          photoData.push(urlPromise);
        }
      };
      
      setSearchPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[index].photoArray = photoData;
        updatedPlaces[index].videoArray = videoData;
        updatedPlaces[index].loadingPhotosAndVideo = false;
        return updatedPlaces;
      });

    } catch(e) { 
      setSearchPlaces(prevPlaces => {
        const updatedPlaces = [...prevPlaces];
        updatedPlaces[index].loadingPhotosAndVideoError = true;
        updatedPlaces[index].loadingPhotosAndVideo = false;
        return updatedPlaces;
      });
      console.error(e);
    }
  }

  const handleSearchUser = async(index) => {
    try {
      setSearchUsers(prevUsers => {
        const updatedUsers = [...prevUsers];
        updatedUsers[index].loading = true;
        return updatedUsers;
      });
      const user_id = searchUsers[index].user_search_id;
      var user = await geocore.users.get(user_id);
      var groups = await geocore.users.groups(user_id);

      setSearchUsers(prevUsers => {
        const updatedUsers = [...prevUsers];
        updatedUsers[index].loading = false;
        updatedUsers[index].data = user;
        updatedUsers[index].error = false;
        updatedUsers[index].groups = groups;
        return updatedUsers;
      });
    } catch (e) {
      console.log("user error", e);
      setSearchPlaces(prevUsers => {
        const updatedUsers = [...prevUsers];
        updatedUsers[index].error = e;
        return updatedUsers;
      });
    }
  };


    const handleSearchGroup= async(index) => {
    try {
      setSearchGroups(prevGroup => {
        const updatedGroup = [...prevGroup];
        updatedGroup[index].loading = true;
        return updatedGroup;
      });
      const group_id = searchGroups[index].group_search_id;
      var user = await geocore.groups.get(group_id);

      setSearchGroups(prevGroup => {
        const updatedGroup = [...prevGroup];
        updatedGroup[index].loading = false;
        updatedGroup[index].data = user;
        updatedGroup[index].error = false;
        return updatedGroup;
      });
    } catch (e) {
      console.log("user error", e);
      setSearchGroups(prevGroup => {
        const updatedGroup = [...prevGroup];
        updatedGroup[index].error = e;
        return updatedGroup;
      });
    }
  };





  const handleCopyText = async (textToCopy, type) => {
    try {
      await navigator.clipboard.writeText(textToCopy);

      switch(type) {
        case "copyUserT":
          setCopyUserT(true);
          setTimeout(() => {
            setCopyUserT(false);
          }, 1000);
          break;
        case "copyUserTPass":
          setCopyUserTPass(true);
          setTimeout(() => {
            setCopyUserTPass(false);
          }, 1000);
          break;
        case "copyCompany":
          setCopyCompany(true);
          setTimeout(() => {
            setCopyCompany(false);
          }, 1000);
          break;
        case "copySubgroup":
          setCopySubgroup(true);
          setTimeout(() => {
            setCopySubgroup(false);
          }, 1000);
          break;
        case "copyPlace":
          setCopyPlace(true);
          setTimeout(() => {
            setCopyPlace(false);
          }, 1000);
          break;
      }

    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const updateEditingVersion = (versionNew) => {
    setEditingApp((prevEditingApp) => ({
      name: prevEditingApp.name,
      platform: prevEditingApp.platform,
      version: versionNew,
      url: prevEditingApp.url,
    }));
  };

  const updateEditingUrl = (urlNew) => {
    setEditingApp((prevEditingApp) => ({
      name: prevEditingApp.name,
      platform: prevEditingApp.platform,
      version: prevEditingApp.version,
      url: urlNew,
    }));
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  return (
    <div>

    <Dialog open={openDialog} onClose={handleCloseDialog}>
        {!editAppLoading?
        <>
        {editingApp?
        <>
        <DialogTitle className="dialog-title-with-icon">{editingApp.platform === "iOS"? <AppleIcon/>: <AndroidIcon style={{ color: "#3DDC84"}} />} {editingApp.name} - {editingApp.platform}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Use the form below to edit the {editingApp.name} {editingApp.platform} app information.
          </DialogContentText>
          <TextField
            margin="dense"
            id="version"
            label="Version"
            fullWidth
            value={editingApp.version}
            variant="standard"
            onChange={(event) => {
              updateEditingVersion(event.target.value);
            }}
          />
          {editingApp.platform === "Android"?
            <TextField
              margin="dense"
              id="android_url"
              label="Android Apk URL"
              fullWidth
              value={editingApp.url}
              variant="standard"
              onChange={(event) => {
                updateEditingUrl(event.target.value);
              }}
            />
          :
            <TextField
              margin="dense"
              id="ios_endpoint_url"
              label="iOS Endpoint URL"
              fullWidth
              value={editingApp.url}
              variant="standard"
              onChange={(event) => {
                updateEditingUrl(event.target.value);
              }}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleAppUpdatePush}>Update</Button>
        </DialogActions>
        </>:<CircularProgress />}
        </>:<DialogContent style={{justifyContent: "center", display: "flex", alignItems: "center"}}><CircularProgress /><p style={{marginLeft: 20}}>Updating App Data</p></DialogContent>}

      </Dialog>

    <div className="mobile-application-area">
      <div className="app-row hazardview">
      <strong className="app-title hazardview"><HazardViewLogo/></strong>
      <div className="hazardview-apps">
        <div className="app-block">
          <AndroidIcon className="android-icon" style={{ color: "#3DDC84"}} />
          <p><span>Version:</span><strong>{hazardviewApp.android.version}</strong></p>
          <p><span>Last Updated:</span> <strong>{hazardviewApp.android.last_updated}</strong></p>
          <p><span>URL:</span> <strong>{hazardviewApp.android.url !== "n/a"? <a href={hazardviewApp.android.url} target="_blank">Link</a>:hazardviewApp.android.url}</strong></p>
          <Button className="app-edit-btn" onClick={() => handleClickOpenDialog("Android", "Hazardview")}>
            Edit
          </Button>
        </div>
        <div className="app-block">
          <AppleIcon className="ios-icon" />
          <p><span>Version: </span><strong>{hazardviewApp.ios.version}</strong></p>
          <p><span>Last Updated: </span><strong>{hazardviewApp.ios.last_updated}</strong></p>
          <p><span>URL: </span><strong>{hazardviewApp.ios.url !== "n/a"? <a href={hazardviewApp.ios.url} target="_blank">Link</a>:hazardviewApp.ios.url}</strong></p>
          <Button className="app-edit-btn" onClick={() => handleClickOpenDialog("iOS", "Hazardview")}>
            Edit
          </Button>
        </div>
      </div>
      </div>

    </div>

    <div className="testing-info-quick-copy-area">
      <h2 className="section-title testing"><ImportContactsIcon /> Tester IDs</h2>

      <div className="test-id-row">
        <strong>User(T) Id:</strong>
        <Button className={"clickable-copy-btn"} onClick={() => handleCopyText("USE-TNHP-1-T-bfkn6xptx4a", "copyUserT")}>USE-TNHP-1-T-bfkn6xptx4a {copyUserT? <CheckIcon style={{color: "#08ff00"}}/>:<ContentCopyIcon/>} </Button>
      </div>

      <div className="test-id-row">
        <strong>Group Id (Company):</strong>
        <Button className={"clickable-copy-btn"} onClick={() => handleCopyText("GRO-TNHP-1-C-testshi", "copyCompany")}>GRO-TNHP-1-C-testshi {copyCompany? <CheckIcon style={{color: "#08ff00"}}/>:<ContentCopyIcon/>} </Button>
      </div>

      <div className="test-id-row">
        <strong>Subgroup Id (Subgroup of GRO-TNHP-1-C-testshi):</strong>
        <Button className={"clickable-copy-btn"} onClick={() => handleCopyText("GRO-TNHP-1-G-fcahq1753h4", "copySubgroup")}>GRO-TNHP-1-G-fcahq1753h4 {copyCompany? <CheckIcon style={{color: "#08ff00"}}/>:<ContentCopyIcon/>} </Button>
      </div>

      <div className="test-id-row">
        <strong>User(T Type) - USE-TNHP-1-T-bfkn6xptx4a) Password:</strong>
        <Button className={"clickable-copy-btn"} onClick={() => handleCopyText("cffuture20", "copyUserTPass")}>cffuture20 {copyUserTPass? <CheckIcon style={{color: "#08ff00"}}/>:<ContentCopyIcon/>} </Button>
      </div>

      <div className="test-id-row">
        <strong>Place Id:</strong>
        <Button className={"clickable-copy-btn"} onClick={() => handleCopyText("PLA-TNHP-1-USE-TNHP-1-T-bfkn6xptx4a-1677485027634", "copyPlace")}>PLA-TNHP-1-USE-TNHP-1-T-bfkn6xptx4a-1677485027634 {copyPlace? <CheckIcon style={{color: "#08ff00"}}/>:<ContentCopyIcon/>} </Button>
      </div>

    </div>

      <div className="place-control">
        <div className="place-info">
          <h2 className="section-title places"><LocationOnIcon /> Places API</h2>
           <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', flexGrow: 1, alignItems: 'center'}}
          >
            {/*
            <IconButton sx={{ p: '10px' }}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <MenuIcon />
            </IconButton>
             <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}><DownloadIcon/> Download All </MenuItem>
            </Menu>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            */}

            {searchPlaces.map((place,index) => (
            <div key={`search_${index}`} className={"search-container"}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="remove search" onClick={() => handleSearchPlace(index)}>
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={place.place_search_id || ''}
                onChange={(event) => handleInputChange(index, event.target.value)}
                placeholder="Search Place Info By ID"
                inputProps={{ 'aria-label': 'search places' }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSearchPlace(index);
                  }
                }}
              />
              {searchPlaces.length > 1?
              <IconButton type="button" sx={{ p: '10px' }} aria-label="remove search" onClick={() => handleRemoveSearch(index)}>
                <CloseIcon />
              </IconButton>
              :null}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </div>
            ))}

            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleAddPlaceSearch}>
              <AddCircleIcon />
            </IconButton>
          </Paper>
        </div>

        <div className="search-places-wrap">
          {searchPlaces.map((place,index) => (
            <div className={`search-place-info-container ${index > 0? "left-border": null}`}>
              {place.error?
                <div className="error-msg">
                  <strong><ErrorIcon /> Returned Error</strong>
                  <ReactJson src={place.error} collapsed={false} name={`error`} theme="solarized" />
                </div>:
                place.loading?
                <div className="loading-data">
                  <CircularProgress />
                  <p>Getting place: <strong>{place.place_search_id}</strong></p>
                </div>:
                place.data?
                  <>
                  {place && place.photoArray && place.photoArray.length > 0?
                    <div style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}>
                    {place.photoArray.map((image) => (
                      <div style={{display: "flex", marginBottom: "10px"}}>

                      <a href={image.url} alt={image.url} target="_blank"
                        style={
                       {
                        backgroundColor: "#131313",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "5px",
                        borderRadius: "3px",
                        border: "3px solid #0c3039",
                        margin: "0 10px 10px 0",
                      }
                      }
                      ><img src={image.url} style={{
                        width: "auto",
                        maxHeight: "200px",
                        height: "auto",
                      }}></img>
                      <p>{image.key.split("/")[1]}</p>
                      <p>{formatBytes(image.metadata.contentLength)}</p>
                      <p>{image.imageWidth}x{image.imageHeight}</p>
                      <p style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", backgroundColor:"#000", border: "1px solid #464646"}}><span style={{fontSize: "10px"}}>Response time</span>{image.responseTimeMs}ms</p>
                      </a>
                      <div style={{
                        display: "flex",
                        lineBreak: "anywhere",
                        maxWidth: "calc(100% - 300px)"
                      }}>
                        <ReactJson src={image} collapsed={false} name={image.key.split("/")[1]} theme="solarized" />
                      </div>
                      </div>
                    ))}
                    </div>
                    :<>
                    {place.loadingPhotosAndVideoError?
                      <p>{place.imageError}</p>:
                      <>{place.loadingPhotosAndVideo? 
                        <><CircularProgress /><p>Loading Photos and/or Videos</p></>:<p>This Place Has No Photos or Videos</p>}</>
                    }
                  </>}

                   <div style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start"
                    }}>
                  {place && place.videoArray && place.videoArray.length > 0?
                    <>
                    {place.videoArray.map((video) => (
                      <div><a href={video.url} alt={video.url} target="_blank"
                        style={
                       {
                        color: "red"
                      }
                      }
                      >Video Url</a><video src={video.url} style={{
                        width: "auto",
                        maxHeight: "200px",
                        height: "auto",
                      }}></video></div>
                    ))}
                    </>
                    :null}
                    </div>
                  <p style={{backgroundColor: "#000", backgroundColor: "#000",border: "1px solid #636363"}}><span>Place Query Response Time: </span>{place.responseTimeMs}ms</p>
                  <ReactJson src={place.data} collapsed={false} name={`place`} theme="solarized" />
                  </>:
                <div className="no-data">
                  <p>Search above to find place data</p>
                </div>
              }
            </div>
          ))}
        </div>
      </div>


      <div className="place-control">
        <div className="place-info">
          <h2 className="section-title users"><PersonIcon /> User API</h2>
           <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', flexGrow: 1, alignItems: 'center'}}
          >
            {/*
            <IconButton sx={{ p: '10px' }}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <MenuIcon />
            </IconButton>
             <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}><DownloadIcon/> Download All </MenuItem>
            </Menu>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            */}

            {searchUsers.map((user,index) => (
            <div key={`search_user_${index}`} className={"search-container"}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="remove search" onClick={() => handleSearchUser(index)}>
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={user.user_search_id || ''}
                onChange={(event) => handleInputChangeUser(index, event.target.value)}
                placeholder="Search User Info By ID"
                inputProps={{ 'aria-label': 'search places' }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSearchUser(index);
                  }
                }}
              />
              {searchUsers.length > 1?
              <IconButton type="button" sx={{ p: '10px' }} aria-label="remove search" onClick={() => handleRemoveSearchUser(index)}>
                <CloseIcon />
              </IconButton>
              :null}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </div>
            ))}

            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleAddPlaceSearch}>
              <AddCircleIcon />
            </IconButton>
          </Paper>
        </div>

        <div className="search-places-wrap">
          {searchUsers.map((user,index) => (
            <div className={`search-place-info-container ${index > 0? "left-border": null}`}>

              {user.error?
                <div className="error-msg">
                  <strong><ErrorIcon /> Returned Error</strong>
                  <ReactJson src={user.error} collapsed={false} name={`error`} theme="solarized" />
                </div>:
                user.loading?
                <div className="loading-data">
                  <CircularProgress />
                  <p>Getting user: <strong>{user.user_search_id}</strong></p>
                </div>:
                user.data?
                  <div className="user-col">
                    <ReactJson src={user.data} collapsed={false} name={`user`} theme="solarized" />
                    {user.groups?
                      <ReactJson src={user.groups} collapsed={false} name={`groups`} theme="solarized" />:<p>No user groups found</p>
                    }
                  </div>:
                <div className="no-data">
                  <p>Search above to find user data</p>
                </div>
              }
            </div>
          ))}
        </div>
      </div>


            <div className="place-control">
        <div className="place-info">
          <h2 className="section-title group"><GroupsIcon /> Group API</h2>
           <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', flexGrow: 1, alignItems: 'center'}}
          >
            {searchGroups.map((group,index) => (
            <div key={`search_user_${index}`} className={"search-container"}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="remove search" onClick={() => handleSearchGroup(index)}>
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                value={group.group_search_id || ''}
                onChange={(event) => handleInputChangeGroup(index, event.target.value)}
                placeholder="Search Group Info By ID"
                inputProps={{ 'aria-label': 'search places' }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSearchGroup(index);
                  }
                }}
              />
              {searchGroups.length > 1?
              <IconButton type="button" sx={{ p: '10px' }} aria-label="remove search" onClick={() => handleRemoveSearchGroup(index)}>
                <CloseIcon />
              </IconButton>
              :null}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </div>
            ))}

            <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleAddPlaceSearch}>
              <AddCircleIcon />
            </IconButton>
          </Paper>
        </div>

        <div className="search-places-wrap">
          {searchGroups.map((group,index) => (
            <div className={`search-place-info-container ${index > 0? "left-border": null}`}>

              {group.error?
                <div className="error-msg">
                  <strong><ErrorIcon /> Returned Error</strong>
                  <ReactJson src={group.error} collapsed={false} name={`error`} theme="solarized" />
                </div>:
                group.loading?
                <div className="loading-data">
                  <CircularProgress />
                  <p>Getting group: <strong>{group.group_search_id}</strong></p>
                </div>:
                group.data?
                  <div className="user-col">
                    <ReactJson src={group.data} collapsed={false} name={`group`} theme="solarized" />
                  </div>:
                <div className="no-data">
                  <p>Search above to find group data</p>
                </div>
              }
            </div>
          ))}
        </div>
      </div>


    </div>
  );
}


