import React from "react";

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import "./Style_Login.scss";

export default class SignIn extends React.Component {
  state = {
    loading: this.props.loading
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.handleAuthUser(this.state.username,this.state.password);
  }

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({loading: this.props.loading});
    }
  }

  render() {
    return (
      <div className="login-page-container">
        <Paper className="login-signup-form">
          <form onSubmit={this.handleSubmit}>
            <FormControl margin="normal"  required fullWidth>
              <InputLabel htmlFor="username" className="dark-theme-label">User</InputLabel>
              <Input id="username" name="username" className="dark-theme-input" autoComplete="username" onChange={this.handleChange} autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel className="dark-theme-label"  htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                className="dark-theme-input"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
              />
            </FormControl>
              <div className="login-btn-wrap">

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    className="btn-primary login-btn"
                    disabled={this.state.loading}
                    style={{marginTop: "5px"}}
                  >
                    {this.state.loading?
                      <CircularProgress size={20} thickness={6} className='loader-login-spinner' /> : <div><LockOutlinedIcon className='icon-lock' /><span>ログイン</span></div>}
                  </Button>
              </div>
          </form>
        </Paper>
      </div>
    )
  }
}
