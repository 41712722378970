import React, { useState, useEffect } from 'react';
import './App.scss';
import Dashboard from './components/Dashboard';
import SignIn from './components/SignIn';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const geocore = require('@mekasmith/geocore');
const geocore_connection = "https://telenet.geocore.jp/api"
geocore.SETTINGS = {
  PROJECT_ID: 'PRO-TNHP-1',
  BASE_URL: geocore_connection
};

geocore.setup(geocore.SETTINGS.BASE_URL, geocore.SETTINGS.PROJECT_ID);

 
const App = () => {
  const [loading, setIsLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const handleAuthUser = async(username, password) => {
    setIsLoading(true)
    if(username !== "USE-TNHP-1-ADMIN-1") {
      setIsLoading(false)
      return
    }
    geocore.login(username, password).then(receivedToken => {
      if(receivedToken) {
        toast("Auth successful")
        setAuthenticated(true);
        setIsLoading(false);
      }
    }).catch((e) => {
      console.log(e);
      setIsLoading(false)
    });
  };

  return (
    <div className="App">
    <ToastContainer
      autoClose={1500}
      theme="dark"
      position="bottom-center"
    />

    {loading?
    <div style={{display: 'flex', justifyContent: "center", alignItems: "center", height: "100%", width: "100%", position: "fixed"}}><CircularProgress/></div>
      :<>
      {authenticated?
        <Dashboard geocore={geocore} geocore_connection={geocore_connection}/>:
        <SignIn handleAuthUser={handleAuthUser} />
      }
      </>}
    </div>
  );
}

export default App;
